function init() {
    if (!document.body.classList.contains("magnolia-editmode")) {
        const redirects = document.querySelectorAll(".countdownRedirect");

        if (redirects) {
            redirects.forEach(function(redirect) {
                let link = redirect.children[0];
                let url = link.getAttribute("href");
                let delay = redirect.getAttribute("data-delay");
                if (url && /^[1-9][0-9]{2,8}$/.test(delay)) {
                    setTimeout(function() {
                        document.location.href = url;
                    }, delay);
                }
            });
        }
    }
}

// For safari caching issue when clicking back button
window.onpageshow = function(event) {
    if (event.persisted) {
        window.location.reload();
    }
};

export default { init };
