import "../../css/pages/countdownRedirectTemp.scss";
import countdownRedirect from "../modules/countdownRedirect.js";

import BasePage from "./base";

class CountdownRedirectTemp extends BasePage {
    constructor(...opts) {
        super(...opts);
    }

    init() {
        super.init();
    }

    onReady() {
        super.onReady();
        countdownRedirect.init();
    }
}

new CountdownRedirectTemp();
